import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  h3 {
    margin-top: 2em;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;


    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;}
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;

    a {
        color: currentColor;
    }
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 1rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Naše putování po Madeiře: Itinerář na 6 dní</Title>
            <Info>
                <Author>Julia</Author>
                <Date>25. 1. 2024</Date>
            </Info>

            <h3>1. den: Funchal a poznávání města</h3>
            <p>Do Funchalu jsme přiletěly chvíli po půlnoci a tak jsme ihned zamířily najít taxi, které nás dopravilo do předem rezervovaného ubytování cca 15 min od letiště. Hned ráno jsme zamířily autobusem zpět na letiště, do půjčovny, kde jsme měly rezervované auto, které nám umožnilo plnou flexibilitu při poznávání ostrova. První zastávkou bylo Miradouro da Ponto do Rosto. Byly jsme naprosto uchvácené výhledy na oceán a pobřeží a těšily jsme se na to, co bude před námi následující dny. Po návratu do Funchalu jsem si užily jízdu lanovkou do Botanické zahrady, kde jsme si daly okouzlující procházku v obklopení exotických rostlin, obrovských kaktusů a úchvatné panorama města. První den jsme zakončily ubytováním v příjemném městečku Sao Vicente (<a href="https://maps.app.goo.gl/12Qn9HMnejQ6sJ1V6">Gardens Guest House Hostel</a>)</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_1.jpg" alt="Pobřeží Madeiry"></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_2.jpg" alt="Fotografie tří káv s dezerty"></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>*pozn. téměř vedle sebe se nachází dva typy botanické zahrady <a href="https://montepalace.com/desktop/">Jardim Tropical Monte Palace</a> a klasická botanická zahrada <a href="https://visitmadeira.com/pt/o-que-fazer/apaixonados-por-cultura/jardins/jardim-botanico-da-madeira/">Jardim Botánico</a>, spousta návštěvníků spíše doporučuje navštívit zahradu Tropical Monte palace, ale my byly v Jardim Botánico :-)</p>
            <p><strong className="uppercase">Poznatek dne</strong>: pokud si myslíte, že jste dobrý řidič/ řidička, zaleťte si na Madeiru a půjčte si tam auto 😃</p>
            <h3>2. den: Fanal, koupání a levády</h3>
            <p>Druhý den byl plný přírodních krás, které Madeira nabízí. Ráno jsme vyrazily směr Fanal a po cestě se vždy zastavily u vyhlídkových míst, abychom se pokochaly úžasnými výhledy na kopce a oceán. Fanal je magický les nazývaný "Laurissilva," patří k přírodním rezervacím, které jsou zařazeny na seznam světového dědictví UNESCO. Po cca 2,5 hodinovém toulání v magickém a zamlženém lese, jsme vyrazily do Porto Moniz, kde jsme se svlékly z dlouhých kalhot, bund a čepic, rovnou do plavek. Porto Moniz je proslulé svými <a href="https://maps.app.goo.gl/zh36uy9y3UzDafcV9">přírodními bazény</a>, které vznikly díky sopečné činnosti. Skalnatý pobřežní útvar, zahrnující mělké i hlubší části, byl upraven a vytvořen tak, aby se se dalo bezpečně vykoupat. Následně jsme pokračovaly do Calheta na Levada do Risco, kam jsme vyrazily poměrně pozdě (okolo 17:00). Podařilo se nám tam dojít ještě za světla a na cestu zpět jsme využily svozové dopravy nahoru k parkovišti. Celková cesta nám trvala asi 1,5 hodiny i se zastávkami na fotky a kocháním se kolem sebe. </p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_3.jpg" alt="Fanal"></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_4.jpg" alt="Vodopád, Madeira"></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <h3>3. den: Východ slunce na Pico Ruivo a další skvosty</h3>
            <p>Ranní budíček nás probudil ve 04:30, čekal nás výšlap na Pico Ruivo, nejvyšší horu Madeiry. Vstávaly jsme takhle brzy, protože cesta autem na místo trvala něco málo přes hodinku. Z parkoviště jsme v úplné tmě (ano, nezapomeňte si čelovky!) vyrazily asi na hodinový výšlap s cílem chytit východ slunce.Měly jsme obrovské štěstí, počasí je v každém koutu Madeiry nevyzpytatelné a ne vždy je možné natrefit na tak úchvatný východ slunce. Kromě čelovky si také nezapomeňte vzít rukavice, čepici a teplé oblečení. Sice po cestě nahoru se zahřejete, ale nahoře vcelku fouká a čekání na malebný východ může být velmi nepříjemné, pokud nejste dostatečně vybaveni. Z intenzivního výšlapu jsme se přesunuly do Santany, kde jsme si dopřály odpočinek u kávy a prohlédly si historické domečky “palheiros”. Další zastávkou bylo místo Balcoes de Ribeiro Frio, který nabízí úžasný výhled na Ribeiro Frio Valley, je to také skvělé místo pro pozorování ptáčků, a pokud budete mít v ruce nějaké krmení a budete trpěliví, tak je klidně můžete i nakrmit. Po obědě v <a href="https://maps.app.goo.gl/6QaNB2kCiH3UTneA9">Snack - Bar Bela 5</a> jsme si prohlédly místní trh a navštívil vyhlídkový bod <a href="https://maps.app.goo.gl/PU4g89xWFJDM6aEF6">Cabo Girao</a>. Večer jsme se, naprosto vřízené, vrátily do Sao Vicente.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_5.jpg" alt="Julia na vyhlídce"></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_6.jpg" alt="Juli na vyhlídce"></StaticImage>
                </StyledFigure>
            </DoubleImage>

            <StyledFigure>
                <StaticImage src="../assets/images/blog/madeira/madeira_7.jpg" alt="Madeira"></StaticImage>
            </StyledFigure>

            <h4 className="underline">Tradiční jídlo k ochutnání:</h4>
            <ul>
                <li><strong>Bife de atum</strong> - steak z tuňáka</li>
                <li><strong>Espada</strong> - šupinoploutvec /ryba/ s banánem</li>
                <li><strong>Castanhetas</strong> - malé ploché ryby</li>
                <li><strong>Espetada</strong> - špíz z kousků hovězího obalovaného v soli, česneku a másle a grilovaného na dřevě.</li>
                <li><strong>Mořské plody</strong> - velké oblibě se těší chobotnice (polvo), např. ve formě salátu nebo jako hlavní chod s rýží. Jediný druh měkkýše vyskytující se na Madeiře jsou kuželnatky (lapas).</li>
                <li><strong>Pstruh (truta)</strong> - v oblasti Ribeiro Frio a Porto Moniz připravují výborné grilované pstruhy z místních pstružích farem.</li>
            </ul>

            <h3>4. den: Vodopád, výlet lodi a lokální speciality</h3>
            <p>Další den jsem strávily návštěvou Ponta do Sol a <a href="https://maps.app.goo.gl/VmptXoCmVE8EWaiX6">Cascata dos Anjos</a>. Vodopád ke kterému vede cesta velmi zajímavými tunely, a který padá rovnou na silnici. Místo oblíbené turisty, kde se můžete přímo na silnici vyfotit pod vodopádem s krásným výhledem na oceán a pobřeží. O kousek dál jsme se zastavily u skrytého kamenného mostu <a href="https://maps.app.goo.gl/JYKJHHJhUnRBCEr3A">Bridge</a>. Poté jsme se vydaly na výlet lodí za velrybami. Což pro všechny z nás byl dechberoucí zážitek. Velryby jsme opravdu viděly a to velmi zblízka, dokonce jedna proplula přímo pod lodí a tím pobavila celé osazenstvo lodi. Výlet doporučují 3 ze 3 zúčastněných. Večer jsme si dopřály večeři v Sao Vicente s ochutnávkou tradičních místních specialit a nápojů v <a href="https://maps.app.goo.gl/tEcZKTBv8SXEnM4P7">Justinos Wine Bar</a>.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_8.jpg" alt="Selfie na lodi"></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_9.jpg" alt="Cascata dos Anjos"></StaticImage>
                </StyledFigure>
            </DoubleImage>

            <h4 className="underline">Místní tradiční nápoje:</h4>
            <ul>
                <li><strong>Poncha</strong> - alkoholický nápoj Poncha se připravuje jak v tradičně s citronovou kůrou, tak v různých kombinacích s jiným ovocem. Poncha obvykle obsahuje bílý rum, citrón, citrónovou kůru, cukr/med. Díky obsahu šťávy z ovoce působí jako limonáda, může mít ovšem až 60 % alkoholu.</li>
                <li><strong>Nikita</strong> - oblíbený a zároveň osvěžující koklejl, tak je nazývána Nikita. Jedná se o milkshake, který se skládá z ananasového džusu, vanilky, malého piva a malé sklenice vína.</li>
                <li><strong>Madeirské víno</strong> - Madeirské víno se řadí mezi exotická dezertní vína. Toto víno je jedinečné geografickým původem, ale i způsobem výroby, který vznikl náhodou kolem 18. století.</li>
                <li><strong>Madeirské pivo</strong> - Coral</li>
            </ul>

            <h3>5. den: Levada do Caldeirao Verde a relaxace</h3>
            <p>Pátý den byl věnován trekování <strong>Levadou do Caldeirao Verde</strong>, na kterou jsme vyrazily poměrně pozdě, jelikož degustace místních nápojů se nám zlehka protáhla 🙂. Celková túra nám trvala zhruba 4 hodiny - včetně zastávek na fotky. Tato trasa vede přes nádherné horské scenérie a končí u impozantního vodopádu Caldeirão Verde. Levady jsou historické zavlažovací kanály na Madeiře, které byly vybudovány pro vedení vody z horských oblastí na suché jižní části ostrova.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_10.jpg" alt="Levada do Caldeirao Verde"></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_11.jpg" alt="Levada do Caldeirao Verde"></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p>Cesta začíná v Queimadas, což je malebná lokalita lemovaná krásnými lesy a skalnatými útvary. Trasa kopíruje průzračný kanál a překračuje několik mostů, což přispívá k jedinečné atmosféře. Prochází přes několik krátkých tunelů a kolem skalních stěn, což dodává cestě dobrodružný nádech, místy dokonce úzkými úbočím až k prudkým srázům, což poskytuje nádherné výhledy. Během výstupu do vyšších nadmořských výšek budete obklopeni zelenými kopci, ze kterých vás budou přecházet pohledy na okolní horské vrcholy. Nádherná túra!</p>
            <p>Odpoledne jsme strávily relaxováním - poflakování u kafíčka, návšteva Seixalu, pláže s černým pískem a odpočinkem.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_12.jpg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_13.jpg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p><strong className="uppercase">Poznatek dne</strong>: Rozhodně se vyplatí si připlatit za Full Cover pojištění v půjčovně a zvolit mezinárodní půjčovnu, která Vám odpustí kosmetické odřeniny.</p>

            <h3>6. den: Poslední den a návrat domů</h3>
            <p>Poslední den naší dobrodružné výpravy začal návštěvou města Ribeira Brava, procházkou městem, kde jsme po několika metrech objevily tetovací studio, což nám přišlo zrovna pod ruku, jelikož jsme si poslední 3 dny pohrávaly s myšlenkou - nechat si vytetovat nějakou vzpomínku na tento okouzlující ostrov. Nahrálo nám i to, že tatérka měla zrovna po obědě čas na nás všechny. Zašly jsme si tedy na oběd a pak si nechaly vytetovat malou vzpomínku.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/madeira/madeira_14.jpg" alt=""></StaticImage>
            </StyledFigure>
            <p>Večer jsme si užily nezapomenutelný západ slunce na Miradouro do Cristo Rei ve městě Santa Cruz. Zvládly jsme tedy východ i západ slunce na ostrově. Prošly jsme si večerní město Funchal, zastavily se u sochy Cristiana Ronalda a vyrazily směr letiště, kde jsme přespaly ve svém pronajatém autě na parkovišti půjčovny ve Funchalu a následně ve 04:00 ráno vyrazily ke gatu.</p>
            <DoubleImage>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_15.jpg" alt=""></StaticImage>
                </StyledFigure>
                <StyledFigure>
                    <StaticImage src="../assets/images/blog/madeira/madeira_16.jpg" alt=""></StaticImage>
                </StyledFigure>
            </DoubleImage>
            <p><strong className="uppercase">Poznatek dne</strong>: Dávejte si pozor, zda neparkujete před policejní stanicí a na to, kde všude jsou parkovací automaty 😃.</p>
            <p>Po příletu do Lisabonu jsme si užily krátký stopover - procházka městem, kávička a oběd v parku. S bohatou zásobou nových vzpomínek a zážitků jsme se vrátily do Prahy.</p>
            <p>Madeira zanechala ve mně nezapomenutelné dojmy, a já tam pro změnu nechala kousek svého srdce. Zase brzy naviděnou, Madeiro! 😍</p>
            <strong>Pro více tipů, neváhejte se na nás obrátit.</strong>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
